var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "s16-invite__wrapper" }, [
    _c("div", { staticClass: "s16-invite__header" }, [_vm._v("邀请成员")]),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.wrapperLoading,
            expression: "wrapperLoading"
          }
        ],
        staticClass: "s16-invite__content-wrapper"
      },
      [
        _c("div", { staticClass: "s16-invite__content" }, [
          _c(
            "div",
            { staticClass: "s16-invite__logo-prj center h100" },
            [
              _c(
                "el-avatar",
                {
                  staticClass: "h100 w100",
                  attrs: { src: require("@/assets/prjLogo.png") },
                  on: { error: _vm.handleError }
                },
                [
                  _c("img", {
                    attrs: { src: require("./avatar").default.head, alt: "" }
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "s16-invite__prjName center fw-600 h50 wrap" },
            [_vm._v(_vm._s(_vm.prjName))]
          ),
          _c(
            "div",
            { staticClass: "s16-invite__invitor center fw-600 h100" },
            [
              _c(
                "el-avatar",
                {
                  staticClass: "h50 w50 noshrink",
                  attrs: { shape: "circle", src: "https://empty.png" },
                  on: { error: _vm.handleError }
                },
                [
                  _c("img", {
                    attrs: { src: require("./avatar").default.head, alt: "" }
                  })
                ]
              ),
              _vm.isValid
                ? _c("span", { staticClass: "wrap" }, [
                    _vm._v(
                      _vm._s(_vm.userENName) +
                        "(" +
                        _vm._s(_vm.user) +
                        ")邀请您一起协同审图"
                    )
                  ])
                : _c("span", { staticClass: "wrap" }, [
                    _vm._v("[邀请失败]" + _vm._s(_vm.errMsg))
                  ])
            ],
            1
          )
        ])
      ]
    ),
    _c(
      "div",
      { staticClass: "s16-invite__footer" },
      [
        _c(
          "el-button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isWechat,
                expression: "isWechat"
              }
            ],
            attrs: { size: "small" },
            on: { click: _vm.exitPage }
          },
          [_vm._v("暂不加入")]
        ),
        _vm.isValid
          ? _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.inviteAction }
              },
              [_vm._v("加入审图")]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }